import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import { Box, Button, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DataTable from "components/Common/DataTable";
import EmailDomainImage from "components/Common/EmailDomainImage";
import Loader from "components/Common/Loader";
import { useUnsubscribeSender } from "hooks/api/mailbot/mutations";
import { useSenderUnsubscribeDetails } from "hooks/api/mailbot/paginatedQueries";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";
import { getDisplayNumber } from "utils/formatter";
import UnsubscribeCard from "./UnsubscribeCard";

export function UnsubscribeButton({ profile }) {
    const unsubscribeSenderMutation = useUnsubscribeSender();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    if (mailBotEnabledPending || unsubscribeSenderMutation.isPending) {
        return <Loader />;
    }
    return (
        <Button
            onClick={() => {
                unsubscribeSenderMutation.mutate({ senderProfileId: profile.id });
            }}
            disabled={!mailBotEnabled}
            disableElevation
            sx={{
                background: "rgba(239, 186, 66, 0.1)",
                border: mailBotEnabled && "1px solid #EFBA42",
                borderRadius: "4px",
                width: "132px",
                height: "40px",
                "&:hover": {
                    background: "#FFAE02",
                },
            }}
            variant="contained"
            startIcon={
                <UnsubscribeOutlinedIcon
                    sx={{
                        color: "#1B1301",
                    }}
                />
            }
        >
            <Typography color="#1B1301" fontSize={"14px"}>
                Unsubscribe
            </Typography>
        </Button>
    );
}

const columns: GridColDef[] = [
    {
        field: "sender_email",
        headerName: "Sender's Email",
        flex: 2,
        sortingOrder: ["asc", "desc"],
        headerClassName: "bg-white",
        renderCell: (params) => {
            return (
                <Box height={"100%"} display={"flex"} alignItems={"center"} gap={"16px"}>
                    <EmailDomainImage
                        email={params.row.sender_email}
                        domain={params.row.sender_domain}
                        name={params.row.sender_name}
                    />
                    <Typography fontWeight={500} lineHeight={"16.94px"} fontSize={"14px"} color={"#000000"} noWrap>
                        {params.value}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: "total_count",
        headerName: "Emails Received",
        flex: 1,
        sortingOrder: ["asc", "desc"],
        headerClassName: "bg-white",
        valueFormatter: (value) => getDisplayNumber(value),
    },
    {
        field: "total_mailing_lists",
        headerName: "Mailing lists",
        flex: 1,
        sortingOrder: ["asc", "desc"],
        headerClassName: "bg-white",
    },
    {
        field: "custom_rules",
        headerName: "",
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "bg-white",
        resizable: false,
        align: "center",
        renderCell: (params) => {
            if (params.row.unsubscribed) {
                return (
                    <Box display={"flex"} justifyContent={"flex-end"} alignItems="center" height={"100%"} gap={"8px"}>
                        <UnsubscribeOutlinedIcon
                            sx={{
                                color: "#1B130199",
                            }}
                        />
                        <Typography color="#1B130199">Unsubscribed</Typography>
                    </Box>
                );
            } else {
                return (
                    <Box display={"flex"} justifyContent={"flex-end"} height={"100%"} alignItems={"center"}>
                        <UnsubscribeButton profile={params.row} />
                    </Box>
                );
            }
        },
    },
];

const filters = [{ label: "Unsubscribed", field: "unsubscribed", value: true }];

export default function UnsubscribeTable() {
    return (
        <DataTable
            tabTitle="Subscriptions"
            columns={columns}
            useData={useSenderUnsubscribeDetails}
            filters={filters}
            pageSize={20}
            renderCard={({ profile }) => <UnsubscribeCard profile={profile} />}
        />
    );
}
