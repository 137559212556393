import { Avatar, Button, Menu, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useLogoutUser, useUser } from "hooks/api/accounts";
import {
    useActivateMailBotProfile,
    useDeactivateMailBotProfile,
    useDeleteUserMailBotProfile,
} from "hooks/api/mailbot/mutations";
import {
    useCanMailBotEnabled,
    useIsMailBotActive,
    useMailBot,
    useMailBotPreferences,
    useMailBotProfileId,
} from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "components/Common/Loader";
import ContactSupportMenu from "components/Common/Support";
import SnackBar from "components/Common/Snackbar";
import ConfirmDialog from "components/Common/Dialog/ConfirmDialog";

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

export default function SideBarFooter() {
    const { data: userMailBotProfile, isPending } = useMailBot();
    const { data: user, isPending: isUserPending } = useUser();
    const { md } = useCustomMediaQuery();
    const logoutUser = useLogoutUser();
    const deleteProfileMutation = useDeleteUserMailBotProfile();
    const { data: mailbotProfileId, isPending: mailbotProfileIdPending } = useMailBotProfileId();
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const { data: mailBotActive, isPending: mailBotActivePending } = useIsMailBotActive();
    const [showDeactivateConfirmDialog, setShowDeactivateConfirmDialog] = useState(false);
    const navigate = useNavigate();
    const { data: mailbotPreferences, isPending: mailbotPreferencesPending } = useMailBotPreferences();
    const deactivateProfileMutation = useDeactivateMailBotProfile();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [messageText, setMessageText] = useState("");
    const activateProfileMutation = useActivateMailBotProfile();
    const { data: canEnableMailBot, isPending: canEnableMailBotPending } = useCanMailBotEnabled();

    const handleDeactivateUser = () => {
        deactivateProfileMutation.mutate(
            { preferences: mailbotPreferences },
            {
                onSuccess: () => {
                    setShowSuccessMessage(true);
                    setMessageText(
                        "Emailzap has been deactivated. All new Emails post deactivation will go to your inbox.",
                    );
                },
                onError: () => {
                    setShowErrorMessage(true);
                    setMessageText("Error occurred while deactivating Emailzap. Please try again later.");
                },
            },
        );
    };

    const handleActivateUser = () => {
        activateProfileMutation.mutate(
            { preferences: mailbotPreferences },
            {
                onSuccess: () => {
                    setShowSuccessMessage(true);
                    setMessageText("Emailzap has been activated successfully.");
                },
                onError: () => {
                    setShowErrorMessage(true);
                    setMessageText("Error occurred while activating Emailzap. Please try again later.");
                },
            },
        );
    };

    const handleLogout = () => {
        logoutUser.mutate(undefined, {
            onSuccess: () => {
                navigate("/");
                setShowSuccessMessage(true);
                setMessageText("You have been logged out successfully.");
            },
            onError: () => {
                setShowErrorMessage(true);
                setMessageText("Error occurred while logging out. Please try again later.");
            },
        });
    };

    const handleDeleteUser = () => {
        deleteProfileMutation.mutate(mailbotProfileId, {
            onSuccess: () => {
                setShowSuccessMessage(true);
                setMessageText("Your account has been deleted successfully.");
            },
            onError: () => {
                setShowErrorMessage(true);
                setMessageText("Error occurred while deleting your account. Please try again later.");
            },
        });
    };

    if (deleteProfileMutation.isPending || deactivateProfileMutation.isPending || activateProfileMutation.isPending) {
        return <Loader />;
    } else if (logoutUser.isPending || mailBotActivePending || mailbotPreferencesPending) {
        return <Loader />;
    }

    return (
        <Box marginBottom={"100px"} p={2}>
            <Box marginBottom={"16px"}>
                <ContactSupportMenu fullScreen={!md} />
            </Box>
            <PopupState variant="popover" popupId="popup-menu">
                {(popupState) => (
                    <>
                        <Button {...bindTrigger(popupState)} sx={{ display: "flex", gap: "10px", padding: 0 }}>
                            <Box>
                                {!isPending && (
                                    <Avatar
                                        src={userMailBotProfile.user_picture}
                                        sx={{
                                            width: 35,
                                            height: 35,
                                        }}
                                        imgProps={{
                                            referrerPolicy: "no-referrer",
                                        }}
                                    />
                                )}
                            </Box>
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems="flex-start"
                                justifyContent="center"
                            >
                                {!isUserPending && (
                                    <Typography color="#FFFFFF" fontWeight={500} fontSize={"18px"}>
                                        {user.first_name} {user.last_name}
                                    </Typography>
                                )}
                                <Typography color="#AEB9E1">Account settings</Typography>
                            </Box>
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            {!canEnableMailBotPending && canEnableMailBot && (
                                <MenuItem
                                    onClick={() => {
                                        popupState.close();
                                        handleActivateUser();
                                    }}
                                >
                                    Activate MailBot
                                </MenuItem>
                            )}
                            {!mailbotProfileIdPending && (
                                <MenuItem
                                    onClick={() => {
                                        popupState.close();
                                        if (mailBotActive && CURRENT_ENV !== "prod") {
                                            // Don't allow deactivate in production
                                            setShowDeactivateConfirmDialog(true);
                                        } else {
                                            setShowDeleteConfirmDialog(true);
                                        }
                                    }}
                                >
                                    Delete User
                                </MenuItem>
                            )}
                            <MenuItem
                                onClick={() => {
                                    popupState.close();
                                    handleLogout();
                                }}
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </PopupState>
            <ConfirmDialog
                open={showDeleteConfirmDialog}
                onClose={() => setShowDeleteConfirmDialog(false)}
                title="Are you sure you want to delete?"
                content="Emailzap will stop categorising the emails that you receive. You will also lose all the trainings you provided to Emailzap."
                secondaryActionText="No, cancel"
                primaryActionText="Yes, confirm"
                onPrimaryActionConfirm={handleDeleteUser}
            />
            <ConfirmDialog
                open={showDeactivateConfirmDialog}
                onClose={() => setShowDeactivateConfirmDialog(false)}
                title="Do you want to delete or deactivate?"
                content="Deactivating will pause EmailZap's categorisation and all the emails will start flowing back to your inbox. Deleting will also permanently delete all the trainings that you might have provided."
                primaryActionText="Deactivate"
                secondaryActionText="Delete"
                onPrimaryActionConfirm={handleDeactivateUser}
                onSecondaryActionConfirm={() => {
                    setShowDeleteConfirmDialog(true);
                }}
            />
            <SnackBar
                open={showSuccessMessage}
                handleClose={() => setShowSuccessMessage(false)}
                message={messageText}
                severity="success"
            />
            <SnackBar
                open={showErrorMessage}
                handleClose={() => setShowErrorMessage(false)}
                message={messageText}
                severity="error"
            />
        </Box>
    );
}
