import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import Loader from "components/Common/Loader";
import Slide1 from "components/Tour/Slide1";
import Slide2 from "components/Tour/Slide2";
import Slide3 from "components/Tour/Slide3";
import Slide4 from "components/Tour/Slide4";
import "components/Tour/styles.css";
import { MAILBOT_FEATURE_FLAGS } from "defines";
import { useUser } from "hooks/api/accounts";
import { useMailBotServiceProvider } from "hooks/api/mailbot/queries";
import { useIsMailBotFeatureEnabled } from "hooks/useChecks";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ANIMATION_DELAY = 3000;
const TOTAL_STEPS = 4;

const Tour = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { md } = useCustomMediaQuery();
    const [step, setStep] = useState(0);
    const [open, setOpen] = useState(true);
    const { data: user, isPending: isUserPending } = useUser();
    const [progressbar, setProgressbar] = useState(0);
    const { data: serviceProvider, isPending: serviceProviderPending } = useMailBotServiceProvider();
    const isZappedLabelEnabled = useIsMailBotFeatureEnabled(MAILBOT_FEATURE_FLAGS.ZAPPED_LABEL);
    const queryParams = new URLSearchParams(location.search);
    const showOnboardingTour = queryParams.get("show_onboarding_tour");
    if (showOnboardingTour && showOnboardingTour === "True") {
        if (!open) {
            setOpen(true);
        }
    } else if (open) {
        setOpen(false);
    }

    const handleOpenEmailzapApp = () => {
        setOpen(false);
        setStep(0);
        navigate("/mail-bot");
    };

    const handleGoToInbox = () => {
        handleOpenEmailzapApp();
        let url = "";
        if (serviceProvider === "google") {
            url = `https://mail.google.com/mail/u/${user.email}`;
        } else if (serviceProvider === "microsoft") {
            // Outlook doesn't have a direct URL scheme like Gmail for accessing a specific email's inbox
            url = "https://outlook.live.com/mail/0/inbox";
        } else {
            throw new Error(`Unknown service provider: ${serviceProvider}`);
        }
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    /**
     * A React useEffect hook to animate a progress bar over time.
     */
    useEffect(() => {
        const interval = setInterval(() => {
            setProgressbar((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 1;
                } else {
                    clearInterval(interval);
                    return prevProgress;
                }
            });
        }, 90);
        return () => clearInterval(interval);
    }, []);

    /**
     * A React useEffect hook to increment the step value after a delay.
     */
    useEffect(() => {
        let interval: ReturnType<typeof setTimeout> | null = null;
        if (step + 1 < TOTAL_STEPS) {
            interval = setTimeout(() => {
                setStep((prevStep) => prevStep + 1);
            }, ANIMATION_DELAY);
        }

        return () => {
            if (interval) clearTimeout(interval);
        };
    }, [step]);
    const slide1Description = isZappedLabelEnabled
        ? "Creating Zapped and Archiving_EmailZap labels"
        : "Creating low priority and junk labels";
    const tourData = [
        {
            title: "Setup in progress..",
            description: slide1Description,
            slide: <Slide1 />,
        },
        {
            title: "Setup in progress..",
            description: "Setting up a daily digest email for you",
            slide: <Slide2 />,
        },
        {
            title: "Setup in progress..",
            description: "Moving last month’s unwanted emails to low priority and junk labels",
            slide: <Slide3 />,
        },
        {
            title: "Successfully Setup",
            description:
                "EmailZap is now setup! You can go straight to your inbox and experience a cleaner inbox or go to EmailZap app to customise your EmailZap experience.",
            slide: <Slide4 handleOpenEmailzapApp={handleOpenEmailzapApp} handleGoToInbox={handleGoToInbox} />,
        },
    ];

    if (isUserPending || serviceProviderPending) {
        return <Loader />;
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
                style: {
                    width: md ? "655px" : "350px",
                    background: "linear-gradient(to bottom, #FFFFFF, #E2E5FD)",
                    border: "1px solid #EBEBF3",
                    boxShadow: "0px 18px 24px 0px #0000000D",
                },
            }}
        >
            <DialogTitle>
                {step === TOTAL_STEPS - 1 && (
                    <GridCheckCircleIcon sx={{ color: "green", display: "block", fontSize: "24px" }} />
                )}
                {tourData[step]["title"]}
                <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                        className={`bg-[#6E57EE] h-full rounded-full transition-all ease-in-out duration-1200`}
                        style={{ width: `${progressbar}%` }}
                    />
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{tourData[step]["description"]}</DialogContentText>
                <DialogContentText>{tourData[step]["slide"]}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default Tour;
