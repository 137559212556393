import {
    Box,
    Button,
    Dialog,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { useConfigureSenderWorkflows, useUpdateUserTrainingStatus } from "hooks/api/mailbot/mutations";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

export default function FutureDeletePreference({ value, setValue, profile, open, setOpen, handleDeleteEmails }) {
    const configureSenderWorkflowsMutation = useConfigureSenderWorkflows();
    const updateUserTrainingStatusMutation = useUpdateUserTrainingStatus();
    const { md } = useCustomMediaQuery();
    const showHeader =
        profile.workflow_tag !== "trash_email_action" &&
        profile.workflow_tag !== "archive_email_action" &&
        profile.user_training !== "white_list";
    const handleDone = () => {
        // Delete historical emails
        handleDeleteEmails(profile);
        setOpen(false);
        if (value === "auto_delete") {
            configureSenderWorkflowsMutation.mutate({
                senderProfiles: [profile],
                action: "trash_email_action",
            });
        } else if (value === "auto_archive") {
            configureSenderWorkflowsMutation.mutate({
                senderProfiles: [profile],
                action: "archive_email_action",
            });
        } else if (value === "white_list") {
            updateUserTrainingStatusMutation.mutate({
                profiles: [profile],
                trainingLabel: "white_list",
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
                style: {
                    background: "#EFBA42",
                },
            }}
        >
            {showHeader && (
                <Box p={2}>
                    <Typography gutterBottom fontWeight={600} variant={md ? "subtitle1" : "subtitle2"}>
                        EmailZap Deleting
                    </Typography>
                    <Typography noWrap fontSize={md ? "14px" : "12px"}>
                        Trashing mails from <strong>{profile.sender_email}</strong>
                    </Typography>
                </Box>
            )}
            <Divider />
            <Box p={2}>
                <Typography fontWeight={500} fontSize={"14px"} lineHeight={"16.94px"}>
                    How would you like EmailZap to handle future emails from this sender?
                </Typography>
                <FormControl>
                    <FormLabel id="future-delete-label" />
                    <RadioGroup
                        row={md}
                        aria-labelledby="future-delete-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    >
                        <FormControlLabel
                            value="auto_delete"
                            control={<Radio color="default" />}
                            label={
                                <Typography fontWeight={value === "auto_delete" ? 600 : 500}>Auto Delete</Typography>
                            }
                        />
                        <FormControlLabel
                            value="white_list"
                            control={<Radio color="default" />}
                            label={
                                <Typography fontWeight={value === "white_list" ? 600 : 500}>Move to Inbox</Typography>
                            }
                        />
                        <FormControlLabel
                            value="auto_archive"
                            control={<Radio color="default" />}
                            label={<Typography fontWeight={value === "auto_archive" ? 600 : 500}>Archive</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box
                p={2}
                display={"flex"}
                width={"100%"}
                justifyContent="space-around"
                flexDirection={md ? "row" : "column"}
                height={"100%"}
            >
                <Button
                    variant="contained"
                    disableElevation
                    sx={{
                        background: "#1F2337",
                        color: "#FFFFFF",
                        width: md ? "175px" : "100%",
                        marginBottom: md ? "0" : "24px",
                    }}
                    onClick={handleDone}
                >
                    Done
                </Button>
                <Button
                    disableElevation
                    sx={{
                        color: "#1F2337",
                        width: md ? "175px" : "100%",
                        fontWeight: 600,
                        fontSize: "14px",
                    }}
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
            </Box>
        </Dialog>
    );
}
