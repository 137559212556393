import { Search } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    InputAdornment,
    Pagination,
    Stack,
    Tab,
    TablePaginationProps,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid, gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import { useQueryClient } from "@tanstack/react-query";
import Loader from "components/Common/Loader";
import { useMailBotMetadata, useMailBotStatistics } from "hooks/api/mailbot/queries";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { usePusher } from "hooks/usePusher";
import { mailbotKeys } from "queryKeyFactory";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";

const getQueryParams = (ordering, searchQuery, filter) => {
    let q = {} as any;
    // length will be 0 when user clears the sorting, in which case default ordering should be applied
    if (ordering.length > 0) {
        if (ordering[0].sort === "asc") {
            q = { ordering: ordering[0].field };
        } else {
            q = { ordering: `-${ordering[0].field}` };
        }
        // If field is not sender_email then always include sender_email as a secondary ordering field
        if (ordering[0].field !== "sender_email") {
            q = { ordering: `${q.ordering},sender_email` };
        }
    }
    if (searchQuery.length > 0) {
        q = { ...q, sender_email: searchQuery };
    }
    if (!!filter) {
        q = { ...q, [filter.field]: filter.value };
    }
    return q;
};

function CustomPagination({
    page,
    onPageChange,
    className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event as any, newPage - 1);
            }}
        />
    );
}

const showOnboardingLoaderTillSeconds = 600; // Show onboarding loader for maximum 10 minutes
const showOnboardingLoaderTillMessages = 5000; // Show onboarding loader till 5000 messages are scanned

export default function DataTable({
    tabTitle = "All Mails",
    pageSize,
    columns,
    useData,
    getRowClassName = (params) => "",
    renderCard,
    filters,
}) {
    const { md } = useCustomMediaQuery();
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const [ordering, setOrdering] = useState<any>([{ field: "total_count", sort: "desc" }]);
    const [searchText, setSearchText] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [onboardingComplete, setOnboardingComplete] = useState(true);
    const { data: statistics, isPending: isStatisticsPending } = useMailBotStatistics();
    const { data: mailbotMetadata, isPending: mailbotMetadataPending } = useMailBotMetadata();
    const onboardingLoaderTimeRef = useRef(null) as any;
    const [filter, setFilter] = useState<any>();
    const q = getQueryParams(ordering, searchQuery, filter);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data, totalResults, isPending: dataPending } = useData({ page: page + 1, pageSize, queryParams: q });
    /**
     * TODO: Move the logic to a hook and outside the datatable component
     * Currently this logic is here because we need to show the onboarding loader and is common for all three tables.
     * We can move this logic to a hook and use it in all three tables.
     */
    if (
        !isStatisticsPending &&
        !onboardingComplete &&
        statistics.messages_scanned >= showOnboardingLoaderTillMessages
    ) {
        setOnboardingComplete(true);
    }

    usePusher("sender-profiles", "statistics", (data) => {
        const senderProfilesQueryKey = mailbotKeys.senderProfilesPage({
            page: 1,
            queryParams: { ordering: "-total_count,sender_email" },
        });
        const statisticsQueryKey = mailbotKeys.statistics();
        queryClient.setQueryData(statisticsQueryKey, data.statistics);
        if (
            page === 0 &&
            filter === undefined &&
            searchQuery === "" &&
            ordering[0].field === "total_count" &&
            ordering[0].sort === "desc"
        ) {
            queryClient.setQueryData(senderProfilesQueryKey, data.sender_profiles);
        }
    });

    usePusher("sender-profiles", "onboarding-complete", (_: any) => {
        setOnboardingComplete(true);
        queryClient.invalidateQueries({ queryKey: mailbotKeys.preferences() });
        queryClient.invalidateQueries({ queryKey: mailbotKeys.statistics() });
        queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfilesAllPages() });
    });

    useEffect(() => {
        if (!isStatisticsPending && !mailbotMetadataPending) {
            if (mailbotMetadata.onboarding_failed === true) {
                // Currently we are not showing any error message to the user
                setOnboardingComplete(true);
            } else if (
                mailbotMetadata.onboarding_completed === undefined ||
                mailbotMetadata.onboarding_completed === null
            ) {
                const secondsSinceMailBotLastEnabled =
                    (new Date().getTime() - new Date(mailbotMetadata.last_enabled_at).getTime()) / 1000;
                if (
                    secondsSinceMailBotLastEnabled < showOnboardingLoaderTillSeconds &&
                    statistics.messages_scanned < showOnboardingLoaderTillMessages
                ) {
                    setOnboardingComplete(false);
                    // Set onboarding to complete again after 10 minutes since the last time mailbot was enabled
                    onboardingLoaderTimeRef.current = setTimeout(
                        () => {
                            setOnboardingComplete(true);
                        },
                        (showOnboardingLoaderTillSeconds - secondsSinceMailBotLastEnabled) * 1000,
                    );
                }
            } else if (mailbotMetadata?.onboarding_completed === true) {
                setOnboardingComplete(true);
            }
        }
        return () => {
            if (onboardingLoaderTimeRef.current) {
                clearTimeout(onboardingLoaderTimeRef.current);
            }
        };
    }, [
        mailbotMetadata?.onboarding_completed,
        mailbotMetadata?.onboarding_failed,
        isStatisticsPending,
        mailbotMetadataPending,
        mailbotMetadata?.last_enabled_at,
        statistics?.messages_scanned,
    ]);

    useDebounce(
        () => {
            setPage(0);
            setSearchQuery(searchText);
        },
        500,
        [searchText],
    );
    const paginationModel = { page: page, pageSize: pageSize };
    if (subscriptionPending) {
        return <Loader />;
    }
    if (!subscription) {
        return (
            <Card>
                <CardContent>
                    <Typography align="center" variant="subtitle2" color="error">
                        You have not subscribed to any plan yet. Please subscribe to a plan to use this feature.
                    </Typography>
                </CardContent>
                <CardActions
                    sx={{
                        justifyContent: "center",
                    }}
                >
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={() => {
                            navigate("/subscription");
                        }}
                    >
                        Subscribe Now
                    </Button>
                </CardActions>
            </Card>
        );
    }
    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"} flexDirection={!md ? "column" : "row"}>
                <Box>
                    <Typography
                        fontWeight={600}
                        lineHeight={"24px"}
                        gutterBottom
                        variant={!md ? "subtitle2" : "subtitle1"}
                    >
                        Emails received
                    </Typography>
                    <Typography fontWeight={400} variant={!md ? "subtitle2" : "subtitle1"} gutterBottom>
                        Listing down all the mails received in your mail box
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: md ? "436px" : "100%",
                        height: "36px",
                    }}
                >
                    <TextField
                        id="search"
                        type="search"
                        placeholder="Search"
                        variant="outlined"
                        fullWidth
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        sx={{
                            background: "white",
                            outline: 0,
                            "#search:focus": {
                                borderColor: "transparent",
                                outline: "none",
                                boxShadow: "none",
                            },
                        }}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                style: {
                                    height: "36px",
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
            {md && (
                <Box marginBottom={"10px"} borderBottom={"1px solid #D2D9E2"}>
                    <Tabs
                        value={"all"}
                        textColor="inherit"
                        TabIndicatorProps={{
                            sx: {
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                            },
                        }}
                    >
                        <Tab
                            value={"all"}
                            label={tabTitle}
                            sx={{
                                fontWeight: 600,
                                fontSize: "15px",
                            }}
                        />
                    </Tabs>
                </Box>
            )}
            {/* First two filters and rest in dropdown menu */}
            {md && filters.length > 0 && (
                <Stack direction={"row"} p={1} spacing={1}>
                    {filters.map((f) => (
                        <Chip
                            key={f.label}
                            label={f.label}
                            size="medium"
                            color="default"
                            variant={f.field === filter?.field && f.value === filter?.value ? "filled" : "outlined"}
                            onClick={() => {
                                if (f.field === filter?.field && f.value === filter?.value) {
                                    setFilter(undefined);
                                    return;
                                }
                                setFilter({
                                    field: f.field,
                                    value: f.value,
                                });
                            }}
                        />
                    ))}
                </Stack>
            )}
            {dataPending && (
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                    <Loader fullScreen={false} />
                </Box>
            )}
            {!onboardingComplete && (
                <Box p={2} display={"flex"} justifyContent={"center"} width={"100%"} gap={"20px"}>
                    <Loader fullScreen={false} size={20} />
                    <Typography variant="subtitle2" color="textSecondary">
                        Analysing your inbox. Can take upto 10 mins
                    </Typography>
                </Box>
            )}
            {!dataPending && data.length === 0 && (
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Typography variant="subtitle2" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            )}
            {md && !dataPending && data.length > 0 && (
                <DataGrid
                    sx={{
                        background: "#F9FAFB",
                        "& .MuiDataGrid-cell:hover": {
                            color: "primary.dark",
                        },
                    }}
                    rowHeight={!md ? 100 : 50}
                    rows={data}
                    columns={columns}
                    rowCount={totalResults}
                    sortingMode="server"
                    filterMode="server"
                    paginationMode="server"
                    disableColumnFilter
                    disableDensitySelector
                    disableColumnSelector
                    pageSizeOptions={[pageSize]}
                    slots={{
                        pagination: (props) => <GridPagination ActionsComponent={CustomPagination} {...props} />,
                    }}
                    getRowClassName={getRowClassName}
                    initialState={{
                        pagination: { paginationModel },
                        sorting: { sortModel: ordering },
                        density: "comfortable",
                    }}
                    onPaginationModelChange={(newModel) => {
                        setPage(newModel.page);
                    }}
                    onSortModelChange={setOrdering}
                />
            )}
            {!md && !dataPending && data.length > 0 && (
                <Box
                    sx={{
                        marginTop: "10px",
                    }}
                >
                    {data.map((profile) => (
                        <Box
                            marginBottom={"10px"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            key={profile.id}
                        >
                            {renderCard({ profile })}
                        </Box>
                    ))}
                    <Box display={"flex"} justifyContent={"center"}>
                        <Pagination
                            count={Math.ceil(totalResults / pageSize)}
                            page={page + 1}
                            color="primary"
                            onChange={(_, newPage) => {
                                setPage(newPage - 1);
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}
