import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Box, Button, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { useSendSupportEmail } from "hooks/api/mailbot/mutations";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { useState } from "react";
import Loader from "components/Common/Loader";
import SnackBar from "components/Common/Snackbar";

const ContactSupport = ({ open, toggleSupportMenu, fullScreen }) => {
    const [text, setText] = useState("");
    const { md } = useCustomMediaQuery();
    const sendSupportEmailMutation = useSendSupportEmail();
    const [succcessMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const handleSendSupportEmail = () => {
        if (text.length > 0) {
            sendSupportEmailMutation.mutate(
                { text },
                {
                    onSuccess: () => {
                        toggleSupportMenu();
                        setSuccessMessage(
                            "Your query has been sent to our support team. You should hear back from them soon.",
                        );
                    },
                    onError: () => {
                        toggleSupportMenu();
                        setErrorMessage("Your query could not be sent to our support team. Please try after sometime.");
                    },
                },
            );
        }
    };
    return (
        <Box>
            <Dialog
                open={open}
                onClose={toggleSupportMenu}
                fullScreen={!md}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: md ? "500px" : "100%",
                        },
                    },
                }}
            >
                {/* Header */}
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={"10px"}>
                                    <img src="/images/email_logo.png" alt="Email Logo" />
                                    <Typography color="white">
                                        Email<strong>Zap</strong>
                                    </Typography>
                                </Box>
                                <Typography color="white">We are here to help you</Typography>
                            </Box>
                            <IconButton edge="start" color="inherit" aria-label="close" onClick={toggleSupportMenu}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                {/* TextArea */}
                <textarea
                    className="w-full"
                    style={{
                        height: `${fullScreen ? "80vh" : "53.4vh"}`,
                        padding: "3.36vh 0.83vw 47.78vh 0.83vw",
                        gap: "30px",
                        overflowX: "hidden",
                        overflowY: "auto",
                        background: "white",
                        resize: "none",
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                    }}
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value);
                    }}
                    placeholder="Add your message..."
                    maxLength={1000}
                />
                <Button
                    onClick={handleSendSupportEmail}
                    sx={{
                        background: "black",
                        mb: "20px",
                        mx: "20px",
                        color: "white",
                    }}
                >
                    Send message
                </Button>
                {sendSupportEmailMutation.isPending && <Loader />}
            </Dialog>
            <SnackBar
                open={!!succcessMessage}
                handleClose={() => setSuccessMessage("")}
                severity={"success"}
                message={succcessMessage}
            />
            <SnackBar
                open={!!errorMessage}
                handleClose={() => setErrorMessage("")}
                severity={"error"}
                message={errorMessage}
            />
        </Box>
    );
};

export default function ContactSupportMenu({ fullScreen = false }) {
    const [supportOpened, setSupportOpened] = useState(false);
    const toggleSupportMenu = () => {
        setSupportOpened((x) => !x);
    };
    return (
        <>
            <Box
                component={"button"}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"10px"}
                onClick={toggleSupportMenu}
            >
                <div
                    className="rounded-full"
                    style={{
                        background: "linear-gradient(128.49deg, #FFDC8E 19.86%, #EFBA42 68.34%)",
                        padding: "7px",
                        height: "35px",
                        width: "35px",
                    }}
                >
                    <img height={20} width={20} src="/images/chat_icon.png" alt="Contact Support" />
                </div>
                <Typography color="#AEB9E1">Contact EmailZap</Typography>
            </Box>
            <ContactSupport open={supportOpened} toggleSupportMenu={toggleSupportMenu} fullScreen={fullScreen} />
        </>
    );
}
