export const userKeys = {
    all: ["user"],
    details: () => [...userKeys.all, "details"],
};

export const applicationKeys = {
    all: ["application"],
    featureFlags: () => [...applicationKeys.all, "feature-flags"],
};

export const mailbotKeys = {
    all: ["mailbot"],
    statistics: () => [...mailbotKeys.all, "statistics"],
    preferences: () => [...mailbotKeys.all, "preferences"],
    canEnable: () => [...mailbotKeys.all, "preferences", "can-enable"], // depends on preferences
    canDisable: () => [...mailbotKeys.all, "preferences", "can-disable"], // depends on preferences
    isActive: () => [...mailbotKeys.all, "preferences", "is-active"], // depends on preferences
    senderProfilesAllPages: () => [...mailbotKeys.all, "sender-profiles"],
    senderProfilesPage: ({ page, queryParams }) => [...mailbotKeys.all, "sender-profiles", page, queryParams],
    deleteHistoricalEmails: () => [...mailbotKeys.all, "delete-historical-emails"], // Key for deleteHistoricalEmails mutation
};

export const paymentKeys = {
    all: ["payment"],
    pricings: () => [...paymentKeys.all, "pricings"],
    latestSubscription: () => [...paymentKeys.all, "latest-subscription"],
    invoices: () => [...paymentKeys.all, "invoices"],
};
