import { useFeatureFlags } from "hooks/api/applications";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "components/Common/Loader";
import { APPLICATION_TAGS } from "defines";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

export default function SideBarSubList({ tabs, setOpen }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { data: featureFlags, isPending } = useFeatureFlags(true);
    if (isPending) {
        return <Loader />;
    }
    return (
        <List>
            {tabs.map((tab) => {
                if (tab.featureFlag && !featureFlags[APPLICATION_TAGS.EMAILZAP][tab.featureFlag]) {
                    return null;
                }
                return (
                    <ListItem key={tab.title}>
                        <ListItemButton
                            selected={tab.isSelected(pathname)}
                            onClick={() => {
                                setOpen(false);
                                navigate(tab.path);
                            }}
                        >
                            <ListItemText
                                primary={tab.title}
                                sx={
                                    tab.isSelected(pathname)
                                        ? {
                                              background: "linear-gradient(128.49deg, #FFDC8E 19.86%, #EFBA42 68.34%)",
                                              WebkitTextFillColor: "transparent",
                                              WebkitBackgroundClip: "text",
                                              backgroundClip: "text",
                                              textColorFill: "transparent",
                                          }
                                        : {
                                              color: "#AEB9E1",
                                          }
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}
