import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { GridColDef } from "@mui/x-data-grid";
import { CustomRule } from "components/AutoCleaner/CustomRule";
import ZapMailButton from "components/AutoCleaner/ZapMailButton";
import ZappedText from "components/AutoCleaner/Zapped";
import DataTable from "components/Common/DataTable";
import EmailDomainImage from "components/Common/EmailDomainImage";
import UndoButton from "components/Common/UndoButton";
import { useMailBotSenderProfiles } from "hooks/api/mailbot/paginatedQueries";
import { getDisplayNumber } from "utils/formatter";
import AutoCleanerCard from "./AutoCleanerCard";

function CustomRulesCell({ profile }) {
    const workflowConfigured = !!profile.workflow_tag;
    const senderBlockedByEmailZap = !!profile.blocked_by_emailzap;
    const senderBlockedByUser = profile.user_training === "black_list";
    if (!workflowConfigured && !senderBlockedByEmailZap && !senderBlockedByUser) {
        return (
            <Grid container spacing={2}>
                <Grid size={6}>
                    <ZapMailButton profile={profile} />
                </Grid>
                <Grid size={6}>
                    <CustomRule profile={profile} ruleEnabled={false} />
                </Grid>
            </Grid>
        );
    } else if (senderBlockedByEmailZap) {
        return (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                <ZappedText title={"Zapped by EmailZap"} />
                <UndoButton undoTraining={true} trainingLabel="white_list" profile={profile} />
            </Box>
        );
    } else if (senderBlockedByUser) {
        return (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                <ZappedText title={"Zapped by you"} />
                <UndoButton undoTraining={true} trainingLabel="" profile={profile} />
            </Box>
        );
    } else {
        return <CustomRule profile={profile} ruleEnabled={true} />;
    }
}

function SenderEmailCell({ profile }) {
    return (
        <Box display={"flex"} alignItems={"center"} gap={"16px"} height={"100%"}>
            <EmailDomainImage email={profile.sender_email} domain={profile.sender_domain} name={profile.sender_name} />
            <Typography fontWeight={500} noWrap>
                {profile.sender_email}
            </Typography>
        </Box>
    );
}

const columns: GridColDef[] = [
    {
        field: "sender_email",
        headerName: "Sender's Email",
        flex: 2,
        headerClassName: "bg-white",
        sortingOrder: ["asc", "desc"],
        renderCell: (params) => {
            return <SenderEmailCell profile={params.row} />;
        },
    },
    {
        field: "total_count",
        headerName: "Emails Received",
        flex: 1,
        sortingOrder: ["asc", "desc"],
        headerClassName: "bg-white",
        resizable: false,
        valueFormatter: (value) => getDisplayNumber(value),
    },
    {
        field: "read_fraction",
        headerName: "Read By You (%)",
        flex: 1,
        sortingOrder: ["asc", "desc"],
        resizable: false,
        headerClassName: "bg-white",
        valueFormatter: (value) => (value * 100).toFixed(0) + "%",
    },
    {
        field: "custom_rules",
        headerName: "",
        align: "center",
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "bg-white",
        resizable: false,
        renderCell: (params) => {
            return <CustomRulesCell profile={params.row} />;
        },
    },
];

const filters = [
    { label: "Zapped by you", field: "user_training", value: "black_list" },
    { label: "Mark as Spam", field: "workflow_tag", value: "spam_email_action" },
    { label: "Mark as Starred", field: "workflow_tag", value: "star_email_action" },
    { label: "Move to Archive", field: "workflow_tag", value: "archive_email_action" },
    { label: "Mark as Read", field: "workflow_tag", value: "mark_email_as_read_action" },
];

export default function AutoCleanerTable() {
    return (
        <DataTable
            columns={columns}
            useData={useMailBotSenderProfiles}
            pageSize={20}
            filters={filters}
            renderCard={({ profile }) => <AutoCleanerCard profile={profile} />}
        />
    );
}
