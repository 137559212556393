import { GridCheckCircleIcon } from "@mui/x-data-grid";
import Loader from "components/Common/Loader";
import { SubscriptionStatus } from "defines";
import { useCreateCheckoutSession } from "hooks/api/applications";
import { useUpgradeSubscription } from "hooks/api/payments/mutations";
import { useLatestSubscription, usePricings } from "hooks/api/payments/queries";

const PricingCard = ({ price, subscription, hasAnyInvoice }) => {
    const { title, subtitle, title_color, subtitle_color, bg_color, footer_text } = price.metadata;
    const upgradeSubscription = useUpgradeSubscription();
    const createCheckoutSessionMutation = useCreateCheckoutSession();
    // Handle payments for the selected subscription plan
    const isSubscriptionActive =
        !!subscription &&
        [SubscriptionStatus.STATUS_ACTIVE, SubscriptionStatus.STATUS_PAST_DUE].includes(subscription.status);
    const handleCheckout = (data) => {
        createCheckoutSessionMutation.mutate(
            { data },
            {
                onSuccess: (checkoutSession) => {
                    window.location = checkoutSession.redirect_url;
                },
            },
        );
    };
    const onPricingPlanCardClick = ({ price, useCoupon = false, isTrial = false, trialPeriodDays = 0 }) => {
        if (isSubscriptionActive && subscription?.price?.nickname.toLowerCase() !== "free") {
            upgradeSubscription.mutate({ id: subscription.id, newPriceId: price.id });
        } else {
            handleCheckout({
                price: price.id,
                is_trial: isTrial,
                trial_period_days: trialPeriodDays,
                use_coupon: useCoupon,
            });
        }
    };
    // Show coupon card if user has no invoices and price has coupon supported
    const showCouponCard = !hasAnyInvoice && price.amount_off;
    const CouponCard = () => {
        const finalPrice = (price.unit_amount - price.amount_off) / 100;
        return (
            <div className="flex flex-col items-center">
                <div
                    onClick={() => {
                        onPricingPlanCardClick({ price, useCoupon: true });
                    }}
                    className={`py-3 justify-center rounded-3xl w-[180px] flex flex-col items-center transition-transform duration-200 transform hover:scale-105 cursor-pointer`}
                    style={{ backgroundColor: "#260651" }}
                >
                    <h2 className="text-base font-extrabold text-center" style={{ color: "#ffffff" }}>
                        ${finalPrice} for first {price.duration_in_months > 1 ? `${price.duration_in_months}` : ``}{" "}
                        {price.duration_in_months > 1 ? "months" : "month"}
                    </h2>
                    <h5 className="text-[9px] font-bold text-center font-[Inter]" style={{ color: "#E29D03" }}>
                        SUBSCRIBE NOW
                    </h5>
                </div>
                <div className="mt-[3px]">
                    <p className="text-[13px] font-[Inter] text-[#260651] font-medium whitespace-pre-line">
                        ${price.unit_amount / 100} per {price.interval} afterwards
                    </p>
                </div>
            </div>
        );
    };
    return (
        <>
            {showCouponCard ? (
                <CouponCard />
            ) : (
                <div className="flex flex-col items-center">
                    <div
                        onClick={() => {
                            onPricingPlanCardClick({ price });
                        }}
                        className={`py-3 justify-center rounded-3xl w-[180px] flex flex-col items-center transition-transform duration-200 transform hover:scale-105 cursor-pointer`}
                        style={{ backgroundColor: bg_color }}
                    >
                        <h2 className="text-base font-extrabold text-center" style={{ color: title_color }}>
                            {title}
                        </h2>
                        <h5 className="text-[9px] font-medium text-center font-[Inter]" style={{ color: title_color }}>
                            {subtitle}
                        </h5>
                        <h5
                            className="text-[9px] font-medium text-center font-[Inter]"
                            style={{ color: subtitle_color }}
                        >
                            SUBSCRIBE NOW
                        </h5>
                    </div>
                    <div className="mt-[3px]">
                        <p
                            className="text-[13px] font-[Inter] text-[#260651] font-medium whitespace-pre-line"
                            dangerouslySetInnerHTML={{ __html: footer_text }}
                        ></p>
                    </div>
                </div>
            )}
            {(upgradeSubscription.isPending || createCheckoutSessionMutation.isPending) && <Loader />}
        </>
    );
};
const priceRanks = { none: 0, zapdeal: 1, mailbot_monthly: 1, mailbot_annual: 2 };
const inactiveSubscriptions = [
    SubscriptionStatus.STATUS_CANCELED,
    SubscriptionStatus.STATUS_UNPAID,
    SubscriptionStatus.STATUS_INCOMPLETE,
    SubscriptionStatus.STATUS_INCOMPLETE_EXPIRED,
];
export default function PricingCatalog({ hasAnyInvoice }) {
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const { data: pricings, isPending: pricesPending } = usePricings();
    if (subscriptionPending || pricesPending) {
        return <Loader />;
    }

    const currentPriceNickname = subscription?.price.nickname || "none";

    let visiblePaymentPlans = pricings.filter((x) => {
        if (subscription?.price.nickname === "free") {
            return x.nickname === "mailbot_monthly";
        }
        // only show prices that are in the priceRanks array and are higher than the current subscription
        if (priceRanks[x.nickname] === undefined || x.nickname === "zapdeal") {
            return false;
        }
        let isAlreadySubscribed = false;
        subscription?.phases?.forEach((phase) => {
            if (phase["items"][0].price === x.id) {
                isAlreadySubscribed = true;
            }
        });
        if (isAlreadySubscribed) return false;
        if (
            inactiveSubscriptions.includes(subscription?.status as SubscriptionStatus) &&
            x.nickname === currentPriceNickname
        ) {
            // If user is not subscribed to any plan, show the current plan as well
            return true;
        }
        return priceRanks[x.nickname] > priceRanks[currentPriceNickname];
    });

    if (visiblePaymentPlans.length === 0) {
        // No need to show the pricing catalog if the user is already on the highest plan
        return <div></div>;
    }

    const isSubscriptionInactive =
        subscription === null || inactiveSubscriptions.includes(subscription?.status as SubscriptionStatus);

    return (
        <div className="flex flex-col items-center bg-gradient-to-b from-[#FCEED3] to-[#FEFDFB] rounded-[32px] p-10 border border-[#e9e9e9] shadow-md w-full max-w-[1110px]">
            <div className="mb-5">
                <h1 className="text-2xl font-bold text-[#260651] font-[Inter]">
                    {isSubscriptionInactive ? "Select Your Plan" : "Upgrade Your Plan"}
                </h1>
                <ul className="list-none my-4 text-sm font-[Inter] text-[#260651]">
                    <li className="flex items-center mb-2">
                        <GridCheckCircleIcon className="w-5 h-5 mr-2" /> Auto filter emails
                    </li>
                    <li className="flex mb-2">
                        <GridCheckCircleIcon className="w-5 h-5 mr-2" /> Auto archive emails
                    </li>
                    <li className="flex mb-2">
                        <GridCheckCircleIcon className="w-5 h-5 mr-2" /> Daily Digest email
                    </li>
                    <li className="flex  mb-2">
                        <GridCheckCircleIcon className="w-5 h-5 mr-2 text" /> Get important emails delivered in inbox
                    </li>
                    <li className="flex  mb-2">
                        <GridCheckCircleIcon className="w-5 h-5 mr-2" /> No extra price for more features
                    </li>
                </ul>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-center">
                {visiblePaymentPlans.map((price, index) => (
                    <div key={price.id} className="flex flex-col lg:flex-row items-center justify-center">
                        <PricingCard price={price} subscription={subscription} hasAnyInvoice={hasAnyInvoice} />
                        {index < visiblePaymentPlans.length - 1 && (
                            <span className="mx-2 text-[#e29d03] font-bold text-sm mb-2 lg:mt-[-10px]">or</span>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
