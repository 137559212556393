import SquareIcon from "@mui/icons-material/Square";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function FeatureDetailedInfoCard({ title, description }) {
    return (
        <Card
            variant="outlined"
            sx={{
                padding: "10px",
                borderColor: "#D2D9E2",
                width: "100%",
                background: "linear-gradient(180deg, rgba(230, 255, 207, 0.1) 0%, rgba(239, 186, 66, 0.1) 100%)",
                borderRadius: "2px",
            }}
        >
            <CardContent sx={{ padding: "1px" }}>
                <Typography fontWeight={500} lineHeight={"16.94px"} variant="body2" marginBottom={"12px"}>
                    {title}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "space-between" }}>
                    {description.map((desc, index) => (
                        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }} key={index}>
                            <SquareIcon sx={{ color: "#EFBA42", fontSize: "12px" }} />
                            <Typography variant="body2" lineHeight={"16.94px"}>
                                {desc}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
}
