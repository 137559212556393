import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { backendServerAxiosInstance } from "services";
import { getAllQueryParams } from "utils";

enum Status {
    SUCCESS,
    FAILURE,
    LOADING,
}

enum DisplayStatus {
    ORIGINAL_MESSAGE_NOT_FOUND = 1,
    MESSAGE_ALREADY_MOVED_TO_LABEL = 2,
    PREFERENCE_ALREADY_UPDATED = 3,
    ORIGINAL_MESSAGE_IN_TRASH = 4,
    MESSAGE_MOVED_SUCCESS = 5,
    DOMAIN_TRAINING_SUCCESS = 6,
    UNDO_DOMAIN_TRAINING_SUCCESS = 7,
    USER_TRAINING_SUCCESS = 8,
    UNDO_USER_TRAINING_SUCCESS = 9,
    DOMAIN_PREFERENCE_UPDATE_SUCCESS = 10,
    UNDO_DOMAIN_PREFERENCE_UPDATE_SUCCESS = 11,
    SENDER_AUTO_ARCHIVE_PREFERENCE_UPDATE_SUCCESS = 12,
    DOMAIN_AUTO_ARCHIVE_PREFERENCE_UPDATE_SUCCESS = 13,
    OPERATION_NOT_SUPPORTED = 14,
    AUTO_ARCHIVE_CANCEL_SUCCESS = 15,
}

function getDisplayMessage(displayStatus: DisplayStatus, context: { [key: string]: string }): string {
    switch (displayStatus) {
        // Email due to which mailbot sent an alert is not found in the user's mailbox
        case DisplayStatus.ORIGINAL_MESSAGE_NOT_FOUND:
            return "Hi, there is some error processing this email. Can you try this operation on a latest email.";
        // Message is already present in the label to which user is trying to move it (digest, lucene alert, ftso, etc.)
        case DisplayStatus.MESSAGE_ALREADY_MOVED_TO_LABEL:
            return `Message has already been moved to ${context.label_name}.`;
        case DisplayStatus.PREFERENCE_ALREADY_UPDATED:
            return "Your preference is already updated.";
        // Message moved to TRASH cannot be moved to any other label
        case DisplayStatus.ORIGINAL_MESSAGE_IN_TRASH:
            return `A deleted email cannot be moved by Emailzap . You need to first move the email out of ${context.label_name} and then perform this operation.`;
        case DisplayStatus.MESSAGE_MOVED_SUCCESS:
            return `Message moved to ${context.label_name} successfully.`;
        case DisplayStatus.DOMAIN_TRAINING_SUCCESS:
            return `Sure, We will send all mails from ${context.sender_domain} to ${context.label_name} in the future.`;
        case DisplayStatus.UNDO_DOMAIN_TRAINING_SUCCESS:
            return `The operation has been reverted.`;
        case DisplayStatus.USER_TRAINING_SUCCESS:
            return `Sure, We will send all mails from ${context.sender_email} to ${context.label_name} in the future.`;
        case DisplayStatus.UNDO_USER_TRAINING_SUCCESS:
            return `The operation has been reverted.`;
        case DisplayStatus.DOMAIN_PREFERENCE_UPDATE_SUCCESS:
            return `Sure, We will continue to send these alerts from ${context.sender_domain} in future.`;
        case DisplayStatus.UNDO_DOMAIN_PREFERENCE_UPDATE_SUCCESS:
            return `Sure, We will not send these alerts from ${context.sender_domain} in future.`;
        case DisplayStatus.SENDER_AUTO_ARCHIVE_PREFERENCE_UPDATE_SUCCESS:
            return `Sure, We will not archive these emails from ${context.sender_email} anymore.`;
        case DisplayStatus.DOMAIN_AUTO_ARCHIVE_PREFERENCE_UPDATE_SUCCESS:
            return `Sure, We will not archive these emails from ${context.sender_domain} anymore.`;
        case DisplayStatus.AUTO_ARCHIVE_CANCEL_SUCCESS:
            return "Sure, We will not archive this email.";
        case DisplayStatus.OPERATION_NOT_SUPPORTED:
            return "This operation is not supported yet.";
        default:
            return "Something went wrong.";
    }
}

function MailOperations() {
    const search = useLocation().search;
    const navigate = useNavigate();
    const [status, setStatus] = useState(Status.LOADING);
    const [displayMessage, setDisplayMessage] = useState("");

    const invokeBackend = (params) => {
        setStatus(Status.LOADING);
        backendServerAxiosInstance
            .post("/mailbot/mail-operations/", params)
            .then((res) => {
                if (res.status === 200) {
                    setStatus(Status.SUCCESS);
                    const mail_operation_status = res.data.mail_operation_status;
                    const mail_operation_context = res.data.mail_operation_context;
                    setDisplayMessage(getDisplayMessage(mail_operation_status, mail_operation_context));
                }
            })
            .catch((err) => {
                setDisplayMessage(err.response.data["detail"]);
                setStatus(Status.FAILURE);
            });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const params = getAllQueryParams(searchParams);
        invokeBackend(params);
    }, []);

    const renderContent = () => {
        if (status === Status.SUCCESS) {
            return (
                <>
                    <div className="flex flex-col justify-center text-xl sm:text-xl md:text-xl lg:text-2xl xl:text-2xl 2xl:text-2xl 4xl:text-4xl font-bold text-center pb-1 tracking-widest">
                        Success!
                    </div>
                    <div className="text-center text-lg xl:text-lg 2xl:text-xl 4xl:text-2xl mt-4">{displayMessage}</div>
                </>
            );
        } else if (status === Status.FAILURE) {
            return (
                <>
                    <div className="flex flex-col justify-center text-xl sm:text-xl md:text-xl lg:text-2xl xl:text-2xl 2xl:text-2xl 4xl:text-4xl font-bold text-center pb-1 tracking-widest">
                        Failed!
                    </div>
                    {displayMessage ? (
                        <div className="text-center text-lg xl:text-lg 2xl:text-xl 4xl:text-2xl mt-4">
                            {displayMessage}
                        </div>
                    ) : (
                        <button
                            type="button"
                            className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 mt-5"
                            onClick={invokeBackend}
                        >
                            Try again
                        </button>
                    )}
                </>
            );
        } else {
            return (
                <div className="flex flex-col justify-center text-xl sm:text-xl md:text-xl lg:text-2xl xl:text-2xl 2xl:text-2xl 4xl:text-4xl font-bold text-center pb-1 tracking-widest">
                    Loading...
                </div>
            );
        }
    };

    return (
        <>
            <div className="flex justify-center flex-col w-full h-screen">
                <div className="flex justify-center flex-col h-full">
                    <div className="flex justify-center flex-col xl:pt-10 space-y-28 sm:space-y-16 md:space-y-16 lg:space-y-10 xl:space-y-16 bg-[#b5afa5]  w-full p-5 lg:px-48 mx-auto my-auto h-full">
                        <div className="mx-auto flex items-center pb-5 lg:flex lg:gap-x-12 md:flex md:gap-x-12 h-16">
                            <img
                                className="h-10 cursor-pointer"
                                src="/images/emailzapByExecfn.png"
                                alt="Your Company"
                                onClick={() => {
                                    navigate("/");
                                }}
                            />
                        </div>

                        <div className="my-auto flex mx-auto py-2 px-2 sm:py-4 sm:px-20 md:py-4 md:px-20 lg:py-4 lg:px-20 xl:py-4 xl:px-20 2xl:py-4 2xl:px-20 4xl:py-4 4xl:px-20 justify-center bg-white rounded-lg items-center flex-col w-fit">
                            <div>{renderContent()}</div>
                        </div>

                        <div className="mx-auto my-auto justify-center">
                            <img className="mx-auto my-auto lg:p-10 xl:p-2 md:p-10" src="/images/heroImg.png" alt="" />
                        </div>
                    </div>
                </div>
                <footer className="bg-white dark:bg-gray-800 sm:px-2 pt-2.5 pb-2.5">
                    <div className="w-full mx-auto max-w-screen-xl md:flex md:items-center md:justify-between">
                        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                            © 2024{" "}
                            <a href="http://www.execfn.com/" className="hover:underline">
                                Executive Function
                            </a>
                            . All Rights Reserved.
                        </span>
                        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                            <li>
                                <a href="http://www.execfn.com/" className="mr-4 hover:underline md:mr-6 ">
                                    ExecFn.com
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.execfn.com/terms-of-service"
                                    className="mr-4 hover:underline md:mr-6"
                                >
                                    Terms Of Service
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.execfn.com/privacy-policy"
                                    className="mr-4 hover:underline md:mr-6"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                        </ul>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default MailOperations;
