import { Box, Tab, Tabs } from "@mui/material";
import Tour from "components/Tour";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function HomeLayout() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    if (mailBotEnabledPending) {
        return null;
    }
    return (
        <Box>
            <Tabs
                variant="fullWidth"
                value={pathname}
                sx={{
                    marginBottom: "20px",
                }}
            >
                <Tab label="Home" value={"/"} onClick={() => navigate("/")} />
                {mailBotEnabled && (
                    <Tab label="Preferences" value={"/preferences"} onClick={() => navigate("/preferences")} />
                )}
                <Tab label="Subscription" value={"/subscription"} onClick={() => navigate("/subscription")} />
            </Tabs>
            <Tour />
            <Outlet />
        </Box>
    );
}
