import { Box, Tooltip, Typography } from "@mui/material";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

type ZappedTextProps = {
    title: "Zapped by EmailZap" | "Zapped by you";
};
export default function ZappedText({ title }: ZappedTextProps) {
    const { md } = useCustomMediaQuery();
    return (
        <Box display={"flex"} justifyContent="center" alignItems="center" gap={"4px"}>
            <img src="/images/common/envelop_info.png" alt="Zapped" />
            <Tooltip
                title={
                    title === "Zapped by EmailZap"
                        ? `All emails from this sender will be automatically moved to -Zapped label created by Emailzap`
                        : `All emails from this sender will be moved to -Zapped label`
                }
                enterTouchDelay={0}
                leaveTouchDelay={5000}
            >
                <Typography
                    fontSize={"12px"}
                    fontWeight={500}
                    lineHeight={"14.52px"}
                    color={md ? "#000000" : "#00000080"}
                >
                    {title}
                </Typography>
            </Tooltip>
        </Box>
    );
}
