import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import Loader from "components/Common/Loader";
import UndoButton from "components/Common/UndoButton";
import { useConfigureSenderWorkflows } from "hooks/api/mailbot/mutations";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

function CustomRuleButton(props) {
    const { md } = useCustomMediaQuery();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    if (mailBotEnabledPending) {
        return null;
    }
    return (
        <Button
            {...props}
            disableElevation
            disabled={!mailBotEnabled}
            variant="contained"
            sx={{
                width: "100%",
                background: "#EFBA421A",
                height: "40px",
                border: mailBotEnabled && "1px solid var(--Yellow, #EFBA42)",
            }}
            startIcon={<EditIcon />}
        >
            <Typography fontSize={"14px"} color="#000000" lineHeight={"16.94px"}>
                {!md ? "Custom rule" : "Add custom rule"}
            </Typography>
        </Button>
    );
}

const CustomRuleMapping = {
    star_email_action: "Mark as starred",
    archive_email_action: "Archive",
    trash_email_action: "Move to trash",
    mark_email_as_read_action: "Mark as read",
    spam_email_action: "Mark as spam",
};

interface ICustomRuleOption {
    label: string;
    value: string;
    renderIcon: any;
    dividerBottom?: boolean;
}

const CUSTOM_RULE_OPTIONS: ICustomRuleOption[] = [
    {
        label: "spam_email_action",
        value: "Move to spam",
        renderIcon: () => <ReportOutlinedIcon />,
    },
    {
        label: "archive_email_action",
        value: "Archive",
        renderIcon: () => <ArchiveOutlinedIcon />,
        dividerBottom: true,
    },
    {
        label: "mark_email_as_read_action",
        value: "Mark as read",
        renderIcon: () => <MarkEmailReadOutlinedIcon />,
    },
    {
        label: "star_email_action",
        value: "Mark as starred",
        renderIcon: () => <StarBorderOutlinedIcon />,
    },
];

function CustomRulesMenu({ options, onClick }) {
    const popupState = usePopupState({ variant: "popover", popupId: "custom-rules-menu" });
    return (
        <>
            <CustomRuleButton {...bindTrigger(popupState)} />
            <Menu
                {...bindMenu(popupState)}
                slotProps={{
                    paper: {
                        sx: {
                            width: "250px",
                            p: 1,
                            borderRadius: "2px",
                        },
                    },
                }}
            >
                <Box
                    display={"flex"}
                    gap={"2px"}
                    sx={{
                        p: 1,
                        background: "#EFBA421A",
                        borderRadius: "2px",
                    }}
                >
                    <InfoOutlinedIcon sx={{ color: "#000000" }} />
                    <Typography color="#000000" fontSize={"12px"} lineHeight={"14.52px"}>
                        All these changes will apply to future emails from this sender
                    </Typography>
                </Box>
                {options.map((option) => (
                    <Box key={option.label}>
                        <MenuItem
                            onClick={() => {
                                onClick(option.label);
                                popupState.close();
                            }}
                        >
                            <ListItemIcon>{option.renderIcon()}</ListItemIcon>
                            <ListItemText>{option.value}</ListItemText>
                        </MenuItem>
                        {option.dividerBottom && <Divider />}
                    </Box>
                ))}
            </Menu>
        </>
    );
}

export const CustomRule = ({ profile, ruleEnabled = false }) => {
    const configureSenderWorkflowsMutation = useConfigureSenderWorkflows();
    // Event handlers
    const onRuleConfigure = (label: string) => {
        configureSenderWorkflowsMutation.mutate({ senderProfiles: [profile], action: label });
    };

    return (
        <>
            {ruleEnabled && (
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                    <Box display={"flex"} justifyContent="center" alignItems="center" gap={"4px"}>
                        {CUSTOM_RULE_OPTIONS.find((option) => option.label === profile.workflow_tag)?.renderIcon()}
                        <Typography fontSize={"12px"} fontWeight={500} lineHeight={"14.52px"} color="#000000">
                            {CustomRuleMapping[profile.workflow_tag]}
                        </Typography>
                    </Box>
                    <UndoButton undoCustomRule={true} profile={profile} />
                </Box>
            )}
            {configureSenderWorkflowsMutation.isPending && <Loader />}
            {!ruleEnabled && <CustomRulesMenu options={CUSTOM_RULE_OPTIONS} onClick={onRuleConfigure} />}
        </>
    );
};
