import { useState } from "react";
import Loader from "components/Common/Loader";
import Invoice from "components/Subscription/Invoice";
import PricingCatalog from "components/Subscription/PricingCatalog";
import SubscriptionCard from "components/Subscription/SubscriptionCard";
import { SubscriptionStatus } from "defines";
import { useIsPrimaryProfile } from "hooks/api/mailbot/queries";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useRenewSubscription } from "hooks/api/payments/mutations";
import { useInvoices } from "hooks/api/payments/paginatedQueries";
import { Box } from "@mui/material";
import SubscriptionCancellation from "components/Subscription/SubscriptionCancellation";
import SnackBar from "components/Common/Snackbar";

export default function Subscription() {
    const { data: subscription, isPending } = useLatestSubscription();
    const renewSubscription = useRenewSubscription();
    const [showCancellation, setShowCancellation] = useState(false);
    const { data: invoices, isPending: invoicesPending } = useInvoices();
    const { data: isPrimaryProfile, isPending: isPrimaryProfilePending } = useIsPrimaryProfile();
    const [renewSubscriptionSuccessMessage, setRenewSubscriptionSuccessMessage] = useState<string>("");
    const [renewSubscriptionErrorMessage, setRenewSubscriptionErrorMessage] = useState<string>("");

    // Handle renewal of subscription
    const handleRenew = () => {
        renewSubscription.mutate(subscription!.id, {
            onSuccess: () => {
                setRenewSubscriptionSuccessMessage("Subscription renewed successfully");
            },
            onError: () => {
                setRenewSubscriptionErrorMessage("Error occured while renewing your subscription. Please try again.");
            },
        });
    };

    if (isPending || invoicesPending || isPrimaryProfilePending) {
        return <Loader />;
    }

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"10px"}
            width={"100%"}
            height={"100%"}
            p={2}
        >
            {subscription &&
                subscription?.price?.nickname !== "free" &&
                [
                    SubscriptionStatus.STATUS_ACTIVE,
                    SubscriptionStatus.STATUS_PAST_DUE,
                    SubscriptionStatus.STATUS_CANCELED,
                ].includes(subscription.status as SubscriptionStatus) && (
                    <SubscriptionCard handleRenew={handleRenew} handleCancel={() => setShowCancellation(true)} />
                )}
            {isPrimaryProfile && <PricingCatalog hasAnyInvoice={invoices.length > 0} />}
            {invoices.length !== 0 && <Invoice invoices={invoices} />}
            <SubscriptionCancellation
                subscription={subscription!}
                open={showCancellation}
                setOpen={setShowCancellation}
            />
            {renewSubscription.isPending && <Loader />}
            <SnackBar
                open={!!renewSubscriptionSuccessMessage}
                handleClose={() => setRenewSubscriptionSuccessMessage("")}
                severity={"success"}
                message={renewSubscriptionSuccessMessage}
            />
            <SnackBar
                open={!!renewSubscriptionErrorMessage}
                handleClose={() => setRenewSubscriptionErrorMessage("")}
                severity={"error"}
                message={renewSubscriptionErrorMessage}
            />
        </Box>
    );
}
