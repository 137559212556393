import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { APPLICATION_TAGS, MAILBOT_FEATURE_FLAGS } from "defines";
import { useFeatureFlags } from "hooks/api/applications";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "components/Common/Loader";
import SideBarFooter from "components/Common/Sidebar/SideBarFooter";
import SideBarSubList from "components/Common/Sidebar/SideBarSublist";

const mailbotTabs = [
    { title: "Home", path: "/", isSelected: (pathname) => pathname === "/" },
    {
        title: "Auto Cleaner",
        path: "/mail-bot/auto-cleaner",
        isSelected: (pathname) => pathname === "/mail-bot/auto-cleaner",
    },
    {
        title: "Delete emails",
        path: "/mail-bot/delete-emails",
        isSelected: (pathname) => pathname === "/mail-bot/delete-emails",
        featureFlag: MAILBOT_FEATURE_FLAGS.BULK_DELETE,
    },
    {
        title: "Unsubscribe",
        path: "/mail-bot/unsubscribe",
        isSelected: (pathname) => pathname === "/mail-bot/unsubscribe",
        featureFlag: MAILBOT_FEATURE_FLAGS.UNSUBSCRIBE,
    },
];

const jarvisTabs = [
    {
        title: "Create Workflow",
        path: "/workflows",
        isSelected: (pathname) => pathname === "/workflows" || pathname === "/workflows/create",
    },
    { title: "All Workflows", path: "/workflows/list", isSelected: (pathname) => pathname === "/workflows/list" },
];

const parentTabs = [
    {
        title: "EmailZap",
        path: "/",
        isSelected: (pathname) => pathname === "/" || pathname.startsWith("/mail-bot"),
        subTabs: mailbotTabs,
        featureFlag: MAILBOT_FEATURE_FLAGS.EMAILZAP,
    },
    {
        title: "Jarvis",
        path: "/workflows",
        isSelected: (pathname) => pathname.startsWith("/workflows"),
        featureFlag: MAILBOT_FEATURE_FLAGS.JARVIS,
        subTabs: jarvisTabs,
    },
];

function SideBarNavigationList({ setOpen }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { data: featureFlags, isPending } = useFeatureFlags(true);
    if (isPending) {
        return <Loader />;
    }
    return (
        <List>
            <Box
                marginTop={"24px"}
                marginLeft={"16px"}
                marginBottom={"35px"}
                component={"button"}
                onClick={() => {
                    navigate("/");
                }}
            >
                <img src="/images/common/emailzap_logo.png" alt="EmailZap" height={41} width={94.5} />
            </Box>
            {parentTabs.map((tab) => {
                if (tab.featureFlag && !featureFlags[APPLICATION_TAGS.EMAILZAP][tab.featureFlag]) {
                    return null;
                }
                const isSelected = tab.isSelected(pathname);
                return (
                    <Box key={tab.title}>
                        <ListItem
                            sx={{
                                borderLeft: isSelected ? "5px solid #EFBA42" : "none",
                                borderTopLeftRadius: "4px",
                                borderBottomLeftRadius: "4px",
                            }}
                        >
                            <ListItemButton
                                onClick={() => {
                                    setOpen(false);
                                    navigate(tab.path);
                                }}
                            >
                                <ArticleOutlinedIcon sx={{ color: "white" }} />
                                <ListItemText
                                    primary={tab.title}
                                    sx={{
                                        color: "white",
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <Box marginLeft={5}>
                            <SideBarSubList tabs={tab.subTabs} setOpen={setOpen} />
                        </Box>
                    </Box>
                );
            })}
        </List>
    );
}

export default function SideBar({ open, setOpen }) {
    // md is used to check if the screen size is greater than 1400px
    // It's different from application wide md which is used to check if the screen size is greater than 1024px
    const md = useMediaQuery("(min-width:1400px)");
    return (
        <Box>
            <Drawer
                sx={{
                    width: "255px",
                    maxWidth: !md ? "280px" : "25%",
                    backgroundColor: "#081028",
                }}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                variant={!md ? "temporary" : "permanent"}
                anchor="left"
                PaperProps={{
                    sx: {
                        backgroundColor: "#081028",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        width: "255px",
                        maxWidth: !md ? "280px" : "25%",
                    },
                }}
            >
                <SideBarNavigationList setOpen={setOpen} />
                <SideBarFooter />
            </Drawer>
        </Box>
    );
}
