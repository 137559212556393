import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutationState } from "@tanstack/react-query";
import DataTable from "components/Common/DataTable";
import EmailDomainImage from "components/Common/EmailDomainImage";
import Loader from "components/Common/Loader";
import FutureDeletePreference from "components/DeleteEmails/FutureDeletePreference";
import { useDeleteHistoricalEmails } from "hooks/api/mailbot/mutations";
import { useMailBotSenderProfiles } from "hooks/api/mailbot/paginatedQueries";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";
import { mailbotKeys } from "queryKeyFactory";
import { useState } from "react";
import { getDisplayNumber } from "utils/formatter";
import DeleteEmailsCard from "./DeleteEmailsCard";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import moment from "moment-timezone";

export function FuturePreferenceButton({ profile }) {
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const [value, setValue] = useState("auto_delete");
    const deleteEmailsMutation = useDeleteHistoricalEmails();
    const handleDeleteEmails = (profile) => {
        deleteEmailsMutation.mutate({ profile });
    };
    const [futureDeletePreferenceOpen, setFutureDeletePreferenceOpen] = useState(false);
    const { md } = useCustomMediaQuery();
    if (mailBotEnabledPending) {
        return null;
    }
    let action;
    if (profile.workflow_tag === "trash_email_action") {
        action = { label: "Auto Delete", value: "auto_delete" };
    } else if (profile.workflow_tag === "archive_email_action") {
        action = { label: "Auto Archive", value: "auto_archive" };
    } else if (profile.user_training === "white_list") {
        action = { label: "Auto Inbox", value: "white_list" };
    }
    return (
        <>
            {action && (
                <Button
                    disabled={!mailBotEnabled}
                    disableElevation
                    onClick={() => {
                        setFutureDeletePreferenceOpen(true);
                        setValue(action.value);
                    }}
                    endIcon={<EditIcon sx={{ fontSize: "16px" }} />}
                    sx={{
                        color: "#000000",
                    }}
                >
                    <Typography fontSize={"12px"}>
                        Future emails are set to:{" "}
                        <strong
                            style={
                                md
                                    ? {}
                                    : {
                                          color: "#FF9600",
                                      }
                            }
                        >
                            {action.label}
                        </strong>
                    </Typography>
                </Button>
            )}
            {action === undefined && (
                <Button
                    disabled={!mailBotEnabled}
                    disableElevation
                    onClick={() => {
                        setFutureDeletePreferenceOpen(true);
                    }}
                    sx={{
                        background: "rgba(239, 186, 66, 0.1)",
                        border: mailBotEnabled && "1px solid #EFBA42",
                        borderRadius: "4px",
                        width: "132px",
                        height: "40px",
                        "&:hover": {
                            background: "#FFAE02",
                        },
                    }}
                    variant="contained"
                    startIcon={<DeleteOutlineOutlinedIcon />}
                >
                    <Typography color="#1B1301" fontSize={"14px"}>
                        Delete
                    </Typography>
                </Button>
            )}
            {futureDeletePreferenceOpen && (
                <FutureDeletePreference
                    value={value}
                    setValue={setValue}
                    open={futureDeletePreferenceOpen}
                    setOpen={setFutureDeletePreferenceOpen}
                    profile={profile}
                    handleDeleteEmails={handleDeleteEmails}
                />
            )}
        </>
    );
}

const filters = [
    { label: "Auto Delete", field: "workflow_tag", value: "trash_email_action" },
    { label: "Auto Archive", field: "workflow_tag", value: "archive_email_action" },
    { label: "Auto Inbox", field: "user_training", value: "white_list" },
];

export default function DeleteEmailsTable() {
    const profilesDeleting = useMutationState({
        filters: { mutationKey: mailbotKeys.deleteHistoricalEmails(), status: "pending" },
        select: (mutation: any) => mutation.state.variables.profile.id,
    });
    const columns: GridColDef[] = [
        {
            field: "sender_email",
            headerName: "Sender's Email",
            flex: 2,
            sortingOrder: ["asc", "desc"],
            headerClassName: "bg-white",
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={"16px"} height={"100%"}>
                        <EmailDomainImage
                            email={params.row.sender_email}
                            domain={params.row.sender_domain}
                            name={params.row.sender_name}
                        />
                        <Typography fontWeight={500} noWrap>
                            {params.value}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "total_count",
            headerName: "Emails Received",
            flex: 1,
            sortingOrder: ["asc", "desc"],
            headerClassName: "bg-white",
            renderCell: (params) => {
                if (profilesDeleting.includes(params.row.id)) {
                    return (
                        <Box display="flex" alignItems="center" gap={"8px"} height={"100%"}>
                            <Loader size={20} fullScreen={false} />
                            <Typography fontSize={"14px"} lineHeight={"16.94px"} color="#000000" fontWeight={500}>
                                Deleting mails...
                            </Typography>
                        </Box>
                    );
                } else {
                    return (
                        <Box display="flex" alignItems="center" height={"100%"}>
                            <Typography>{getDisplayNumber(params.value)}</Typography>
                        </Box>
                    );
                }
            },
        },
        {
            field: "recent_timestamp",
            headerName: "Last Email Received",
            flex: 1,
            sortingOrder: ["asc", "desc"],
            headerClassName: "bg-white",
            valueFormatter: (value: any) => {
                return moment(value).format("MMM DD, YYYY");
            },
        },
        {
            field: "custom_rules",
            headerName: "",
            flex: 2,
            sortable: false,
            disableColumnMenu: true,
            headerClassName: "bg-white",
            resizable: false,
            align: "right",
            renderCell: (params) => {
                if (profilesDeleting.includes(params.row.id)) {
                    return null;
                } else {
                    return <FuturePreferenceButton profile={params.row} />;
                }
            },
        },
    ];
    return (
        <DataTable
            columns={columns}
            useData={useMailBotSenderProfiles}
            filters={filters}
            pageSize={20}
            getRowClassName={(params) => {
                if (profilesDeleting.includes(params.row.id)) {
                    return "bg-[#EDEDED]";
                }
                return "border-black border-0";
            }}
            renderCard={({ profile }) => <DeleteEmailsCard profile={profile} />}
        />
    );
}
