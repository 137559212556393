import { Button, Typography } from "@mui/material";
import Loader from "components/Common/Loader";
import { useUpdateUserTrainingStatus } from "hooks/api/mailbot/mutations";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";

export default function ZapMailButton({ profile }) {
    const updateTrainingStatusMutation = useUpdateUserTrainingStatus();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    if (mailBotEnabledPending) {
        return null;
    }
    return (
        <>
            {updateTrainingStatusMutation.isPending && <Loader />} 
            <Button
                variant="contained"
                sx={{
                    background: "#FFAE02",
                    borderRadius: "4px",
                    height: "40px",
                    width: "100%",
                }}
                disableElevation
                disabled={!mailBotEnabled}
                onClick={() => {
                    updateTrainingStatusMutation.mutate({ profiles: [profile], trainingLabel: "black_list" });
                }}
                startIcon={<img src="/images/common/zap_icon.png" alt="Zap" />}
            >
                <Typography fontSize={"14px"} fontWeight={500} color="#000000" lineHeight={"16.94px"}>
                    Zap the mail
                </Typography>
            </Button>
        </>
    );
}
