import { useQuery } from "@tanstack/react-query";
import { mailbotKeys } from "queryKeyFactory";
import { getMailBotSenderProfiles, getSenderUnsubscribeDetails } from "services/mailbot/paginatedQueries";

export function useMailBotSenderProfiles({ page, pageSize, queryParams = {} }) {
    const { data, isPending } = useQuery({
        queryKey: mailbotKeys.senderProfilesPage({ page, queryParams }),
        queryFn: () => getMailBotSenderProfiles({ page, pageSize, queryParams }),
    });
    return {
        data: data?.results,
        totalResults: data?.count || 0,
        isPending,
    };
}

export function useSenderUnsubscribeDetails({ page, pageSize, queryParams = {} }) {
    const { data, isPending } = useQuery({
        queryKey: ["mailbot", "sender-unsubscribe-details", page, queryParams],
        queryFn: () => getSenderUnsubscribeDetails({ page, pageSize, queryParams }),
    });
    return {
        data: data?.results,
        totalResults: data?.count,
        isPending,
    };
}
