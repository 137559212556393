import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

export default function FeatureCard({
    title = "Important emails are always delivered to your inbox",
    description = "Even if a sender is Zapped, important emails like OTPs, security alerts, or payment info will still reach your inbox, ensuring you don’t miss them.",
}) {
    const { md } = useCustomMediaQuery();
    return (
        <Card
            variant="outlined"
            sx={{
                padding: "10px",
                borderColor: "#CFD6EB",
                width: "100%",
            }}
        >
            <CardContent sx={{ padding: "1px" }}>
                <Typography
                    fontWeight={600}
                    lineHeight={"20px"}
                    marginBottom={"12px"}
                    variant={!md ? "subtitle2" : "subtitle1"}
                    align={!md ? "center" : "inherit"}
                >
                    {title}
                </Typography>
                <Typography fontSize={"12px"} lineHeight={"14.52px"}>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}
