import { loginMailBotProfile } from "utils/login";
import { getAllQueryParams } from "utils";
import { useLocation } from "react-router-dom";

export default function Login() {
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const trackingParameters = getAllQueryParams(params);
    return (
        <div className="flex flex-col max-w-xl h-screen mx-auto justify-between font-[Inter]">
            <div className="mt-8 flex justify-center">
                <img
                    className="h-10 cursor-pointer"
                    src="/images/emailzapByExecfn.png"
                    alt="Your Company"
                    onClick={() => {
                        window.location.href = "http://www.execfn.com/";
                    }}
                />
            </div>
            <div className="flex justify-center flex-col mt-32 px-4 sm:px-0">
                <div className="text-4xl font-bold flex justify-center text-[#1A1A1A] text-center">Welcome back</div>
                <div className="text-[#858694] text-base font-medium my-5 text-center">
                    Sign in to set your preferences or see stats on those who send you emails
                </div>
                <div>
                    <div
                        className="flex flex-row justify-center items-center border border-[#E2E8F0] py-2.5 gap-3 rounded-full mx-auto mx-5 sm:mx-20 cursor-pointer shadow-md"
                        onClick={() => loginMailBotProfile("google", trackingParameters)}
                    >
                        <img className="w-5 cursor-pointer" src="/images/gmail_logo.png" alt="Gmail Logo" />
                        <div className="font-medium text-[#1A1A1A]">Continue with Gmail</div>
                    </div>
                    <div
                        className="flex flex-row justify-center items-center border border-[#E2E8F0] py-2.5 gap-3 rounded-full mx-auto mx-5 sm:mx-20 cursor-pointer shadow-md mt-5"
                        onClick={() => loginMailBotProfile("microsoft", trackingParameters)}
                    >
                        <img className="w-5 cursor-pointer" src="/images/outlook_logo.png" alt="Outlook logo" />
                        <div className="font-medium text-[#1A1A1A]">Continue with Outlook</div>
                    </div>
                </div>
            </div>
            <div className="mt-8 flex justify-center">
                <img className="" src="/images/landing_page_clip_art.svg" alt="Clip art" />
            </div>
        </div>
    );
}
