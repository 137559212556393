import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    Box,
    Button,
    createTheme,
    CssBaseline,
    IconButton,
    ThemeProvider,
    Toolbar,
    useMediaQuery,
} from "@mui/material";
import AccessRevokedDialog from "components/Common/Dialog/AccessRevokedDialog";
import InsufficientScopesDialog from "components/Common/Dialog/InsufficientScopesDialog";
import OfflineStatusDialog from "components/Common/Dialog/OfflineStatusDialog";
import SideBar from "components/Common/Sidebar";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const theme = createTheme({
    palette: {
        primary: {
            dark: "#a7822e",
            light: "#f2c767",
            main: "#EFBA42",
            contrastText: "#000000",
        },
        background: {
            default: "#F9FAFB",
        },
    },
    typography: {
        fontFamily: "Inter",
        button: {
            textTransform: "none",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 1024,
            lg: 1300,
            xl: 1736,
        },
    },
});

function AppContent() {
    const md = useMediaQuery("(min-width:1400px)");
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <Box display={"flex"} height={"100%"} width={"100%"}>
            <CssBaseline /> {/* CssBaseline is used to reset the default browser styling */}
            <SideBar open={open} setOpen={setOpen} />
            <InsufficientScopesDialog />
            <OfflineStatusDialog />
            <AccessRevokedDialog />
            <Box
                component="main"
                width={"100%"}
                sx={
                    !md
                        ? {
                              background: "white",
                          }
                        : {}
                }
            >
                {!md && (
                    <AppBar
                        position="sticky"
                        sx={{
                            backgroundColor: "white",
                        }}
                    >
                        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                            <IconButton aria-label="menu" onClick={() => setOpen(true)}>
                                <MenuIcon />
                            </IconButton>
                            <Button
                                onClick={() => {
                                    navigate("/");
                                }}
                                startIcon={
                                    <img
                                        src="/images/common/app_bar_icon.png"
                                        alt="EmailZap"
                                        height={41}
                                        width={94.5}
                                    />
                                }
                            />
                        </Toolbar>
                    </AppBar>
                )}
                <Outlet />
            </Box>
        </Box>
    );
}

export default function AppLayout() {
    return (
        <ThemeProvider theme={theme}>
            <AppContent />
        </ThemeProvider>
    );
}
