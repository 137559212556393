import { Box, Typography } from "@mui/material";
import FeatureDetailedInfoCard from "components/Common/FeatureDetailedInfoCard";
import MobileTabs from "components/Common/MobileTabs";
import UnsubscribeTable from "components/Unusbscribe/UnsubscribeTable";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

const featureTitle = "How unsubscribe works";
const featureDescription = [
    `When you hit unsubscribe, EmailZap will send an email request to the sender to unsubscribe you from mailing list`,
    `Additionally, EmailZap will ensure that all future emails from an unsubscribed sender get automatically deleted`,
    `There are certain senders that require you to go their webpage to unsubscribe. Those senders will not show up here`,
];

export default function Unsubscriber() {
    const { md } = useCustomMediaQuery();
    return (
        <Box>
            {!md && <MobileTabs />}
            <Box
                marginLeft={"auto"}
                marginRight={"auto"}
                marginTop={md ? "68px" : "10px"}
                marginBottom={"32px"}
                minWidth={!md ? "375px" : "1000px"}
                maxWidth={!md ? "576px" : "1116px"}
                p={2}
            >
                <Box marginBottom={"26px"}>
                    <Typography
                        variant={!md ? "subtitle2" : "h6"}
                        fontWeight={600}
                        lineHeight={"24.2px"}
                        gutterBottom
                        color="#2B333B"
                    >
                        Unsubscribe
                    </Typography>
                    <Typography variant="subtitle2" lineHeight={"16.94px"} color="#2B333B">
                        Unsubscribe from all mailing lists in one go
                    </Typography>
                </Box>
                <Box marginBottom={"20px"}>
                    <FeatureDetailedInfoCard title={featureTitle} description={featureDescription} />
                </Box>
                <UnsubscribeTable />
            </Box>
        </Box>
    );
}
