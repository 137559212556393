import { Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export default function MobileTabs() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <Tabs variant="fullWidth" value={pathname}>
            <Tab
                label="Auto cleaner"
                value={"/mail-bot/auto-cleaner"}
                onClick={() => navigate("/mail-bot/auto-cleaner")}
            />
            <Tab
                label="Delete mails"
                value={"/mail-bot/delete-emails"}
                onClick={() => navigate("/mail-bot/delete-emails")}
            />
            <Tab
                label="Unsubscribe"
                value={"/mail-bot/unsubscribe"}
                onClick={() => navigate("/mail-bot/unsubscribe")}
            />
        </Tabs>
    );
}
