import Loader from "components/Common/Loader";
import { useCreateCheckoutSession } from "hooks/api/applications";
import { useMailBotMetadata } from "hooks/api/mailbot/queries";
import { useLatestSubscription, usePricings } from "hooks/api/payments/queries";
import { useLocation, useNavigate } from "react-router-dom";

const StripeCheckout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const createCheckoutSessionMutation = useCreateCheckoutSession();
    const { data: pricings, isPending: pricesPending } = usePricings();
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const { data: mailbotMetadata, isPending: mailbotMetadataPending } = useMailBotMetadata();
    if (!pricesPending && !subscriptionPending && !mailbotMetadataPending) {
        let areAllScopesGranted = true;
        if (!mailbotMetadataPending && mailbotMetadata.all_scopes_granted === false) {
            areAllScopesGranted = false;
        }
        const queryParams = new URLSearchParams(location.search);
        const redirectToStripeCheckout = async (priceId: string, useCoupon: boolean) => {
            createCheckoutSessionMutation.mutate(
                { data: { price: priceId, use_coupon: useCoupon }, queryParams: queryParams },
                {
                    onSuccess: (checkoutSession) => {
                        window.location = checkoutSession.redirect_url;
                    },
                },
            );
        };
        const pricingNickname = queryParams.get("stripe_payment_plan") || "";
        const mailbotProfileCreated = queryParams.get("mailbot_profile_created") === "True";
        const useCoupon = queryParams.get("use_coupon") === "true";
        const price = pricings.find((pricing) => pricing.nickname === pricingNickname);
        if (mailbotProfileCreated && areAllScopesGranted && !subscription && price) {
            redirectToStripeCheckout(price.id, useCoupon);
        } else {
            navigate("/");
        }
    }
    return <Loader />;
};

export default StripeCheckout;
