import { Backdrop, CircularProgress } from "@mui/material";

const Loader = ({ fullScreen = true, size = 30 }) => {
    if (fullScreen) {
        return (
            <Backdrop open={true} style={{ zIndex: 9999 }}>
                <CircularProgress size={size} />
            </Backdrop>
        );
    } else {
        return <CircularProgress size={size} />;
    }
};

export default Loader;
