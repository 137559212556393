import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

export default function ExploreCard({ title, description, onClick, children }) {
    const { md } = useCustomMediaQuery();
    return (
        <Card
            variant="outlined"
            sx={{
                backgroundColor: "#EFF2F5",
                width: "100%",
                padding: "10px",
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: !md ? "column" : "row" }}>
                <Box sx={{ display: "flex", flexDirection: !md ? "row" : "row" }}>
                    {children}
                    <Box marginLeft={!md ? "10px" : "20px"}>
                        <Typography
                            gutterBottom
                            fontWeight={600}
                            lineHeight={!md ? "16.94px" : "20px"}
                            color={"#1C1C1C"}
                            variant={!md ? "subtitle2" : "subtitle1"}
                        >
                            {title}
                        </Typography>
                        <Typography lineHeight={!md ? "16.94px" : "20px"} color={"#1C1C1C"} variant={"body2"}>
                            {description}
                        </Typography>
                    </Box>
                </Box>
                <CardActions>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={onClick}
                        sx={{
                            width: !md ? "100%" : "175px",
                            height: "42px",
                            fontWeight: 600,
                        }}
                    >
                        Explore
                    </Button>
                </CardActions>
            </Box>
        </Card>
    );
}
