import { backendServerAxiosInstance } from "services";

export const updateMailBotPreferences = async (preferences: any) => {
    const response = await backendServerAxiosInstance.put("/mailbot/preferences/", { preferences: preferences });
    return response.data;
};

export const deleteUserMailBotProfile = async (id) => {
    const response = await backendServerAxiosInstance.delete(`/mailbot/mailbot-profile/${id}/`);
    return response.data;
};

export const updateUserTrainingStatus = async ({ profiles, trainingLabel }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/sender-profiles/update-training/`, {
        sender_profiles: profiles,
        user_training: trainingLabel,
    });
    return response.data;
};

export const deactivateMailBotProfile = async ({ preferences }) => {
    return updateMailBotPreferences({ ...preferences, mailbot_enabled: false });
};

export const activateMailBotProfile = async ({ preferences }) => {
    return updateMailBotPreferences({ ...preferences, mailbot_enabled: true });
};

export const switchMailProfile = async (user_mailbot_profile_id) => {
    const response = await backendServerAxiosInstance.post("/mailbot/secondary-profiles/switch/", {
        user_profile_to_switch_id: user_mailbot_profile_id,
    });
    return response.data;
};
export const configureSenderWorkflows = async ({ senderProfiles, action }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/sender-workflow/`, {
        sender_profiles: senderProfiles,
        action: action,
    });
    return response.data;
};

export const deleteConfiguredSenderWorkflows = async ({ senderProfileIds }) => {
    const response = await backendServerAxiosInstance.delete(`/mailbot/sender-workflow/${senderProfileIds}/`);
    return response.data;
};

export const sendSupportEmail = async ({ text }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/support/`, { body: text });
    return response.data;
};

export const deleteHistoricalEmails = async ({ profile }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/sender-profiles/bulk-delete/`, {
        sender_profile: profile,
    });
    return response.data;
};

export const unsubscribeSender = async ({ senderProfileId }) => {
    const response = await backendServerAxiosInstance.post(
        `/mailbot/unsubscribe-details/${senderProfileId}/unsubscribe/`,
    );
    return response.data;
};
