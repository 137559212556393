import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import { Box, Divider, Drawer, IconButton, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { getDisplayNumber } from "utils/formatter";
import { UnsubscribeButton } from "./UnsubscribeTable";

function BottomSheet({ open, onClose, profile }) {
    return (
        <Drawer
            anchor={"bottom"}
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    height: "40%",
                },
            }}
        >
            <Box
                sx={{
                    background: "#F9F9FB",
                    height: "45px",
                }}
                display={"flex"}
                alignItems={"center"}
                p={1}
            >
                <Typography fontWeight={600} fontSize={"14px"}>
                    Email Details
                </Typography>
            </Box>
            <Box p={2}>
                <Box display={"flex"} alignItems={"center"} gap={"8px"} marginBottom={"10px"}>
                    <Typography color="##111827" variant="subtitle2" fontWeight={500} lineHeight={"16px"} noWrap>
                        {profile.sender_email}
                    </Typography>
                </Box>
                <Box display={"flex"} gap={"8px"}>
                    <Typography color="#616265" variant="body2">
                        Mails: {getDisplayNumber(profile.total_count)}
                    </Typography>
                    <Typography color="#616265" variant="body2">
                        Mailing Lists: {getDisplayNumber(profile.total_mailing_lists)}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box p={1} marginTop={"20px"}>
                {!profile.unsubscribed && <UnsubscribeButton profile={profile} />}
                {profile.unsubscribed && (
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                        <UnsubscribeOutlinedIcon
                            sx={{
                                color: "#1B130199",
                            }}
                        />
                        <Typography variant="subtitle2" color="#1B130199">
                            Unsubscribed
                        </Typography>
                    </Box>
                )}
            </Box>
        </Drawer>
    );
}

export default function UnsubscribeCard({ profile }) {
    const [selected, setSelected] = useState(false);
    return (
        <>
            <Paper
                variant="outlined"
                sx={{
                    width: "100%",
                    boxShadow: "0px 1px 1px 0px #0000001A",
                    border: 0,
                    borderRadius: 0,
                    background: "white",
                }}
            >
                <Box p={2}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box display={"flex"} alignItems={"center"} gap={"8px"} marginBottom={"10px"} width={"90%"}>
                            <MailOutlineIcon
                                sx={{
                                    color: "#111827",
                                }}
                            />
                            <Typography
                                color="##111827"
                                variant="subtitle2"
                                fontWeight={500}
                                lineHeight={"16.94px"}
                                noWrap
                            >
                                {profile.sender_email}
                            </Typography>
                        </Box>
                        <IconButton
                            onClick={() => {
                                setSelected(!selected);
                            }}
                        >
                            <MoreVertIcon
                                sx={{
                                    color: "#111827",
                                }}
                            />
                        </IconButton>
                    </Box>
                    <Box display={"flex"} gap={"8px"} marginBottom={"20px"}>
                        <Typography color="#616265" variant="body2">
                            Mails: <strong>{getDisplayNumber(profile.total_count)}</strong>
                        </Typography>
                        <Typography color="#616265" variant="body2">
                            Mailing Lists: <strong>{getDisplayNumber(profile.total_mailing_lists)}</strong>
                        </Typography>
                    </Box>
                    {profile.unsubscribed && (
                        <Box
                            sx={{
                                background: "#F9FAFB",
                            }}
                            display={"flex"}
                            py={"2px"}
                            justifyContent={"center"}
                        >
                            <Typography fontSize={"10px"} color="#616265" fontWeight={500}>
                                Unsubscribed
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Paper>
            <BottomSheet open={selected} onClose={() => setSelected(false)} profile={profile} />
        </>
    );
}
