import { Box, Typography } from "@mui/material";
import AutoCleanerTable from "components/AutoCleaner/AutoCleanerTable";
import FeatureDetailedInfoCard from "components/Common/FeatureDetailedInfoCard";
import MobileTabs from "components/Common/MobileTabs";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

const featureTitle = "How Auto-cleaner works";
const featureDescription = [
    `Auto cleaner analyses your past email behaviour and advanced algorithm to decide which email should land in your inbox`,
    `Any email which is Zapped, either by you or EmailZap, will get sent to the "-Zapped" label created in your mailbox`,
    `Undoing a Zapped email will lead to future emails from that sender being sent to your inbox`,
];

export default function AutoCleaner() {
    const { md } = useCustomMediaQuery();
    return (
        <Box>
            {!md && <MobileTabs />}
            <Box
                marginLeft={"auto"}
                marginRight={"auto"}
                marginTop={md ? "68px" : "10px"}
                marginBottom={"32px"}
                minWidth={!md ? "375px" : "1000px"}
                maxWidth={!md ? "576px" : "1116px"}
                p={2}
            >
                <Box marginBottom={"26px"}>
                    <Typography
                        variant={!md ? "subtitle2" : "h6"}
                        fontWeight={600}
                        lineHeight={"24.2px"}
                        gutterBottom
                        color="#2B333B"
                    >
                        Auto Cleaner
                    </Typography>
                    <Typography variant="subtitle2" lineHeight={"16.94px"} color="#2B333B">
                        Let EmailZap handle your inbox
                    </Typography>
                </Box>
                <Box marginBottom={"20px"}>
                    <FeatureDetailedInfoCard title={featureTitle} description={featureDescription} />
                </Box>
                <AutoCleanerTable />
            </Box>
        </Box>
    );
}
