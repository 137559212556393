import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mailbotKeys, paymentKeys } from "queryKeyFactory";
import { cancelSubscription, renewSubscription, upgradeSubscription } from "services/payments/mutations";

export function useRenewSubscription() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: string) => renewSubscription(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: paymentKeys.latestSubscription() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.isActive() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.canEnable() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.canDisable() });
        },
    });
}

export function useCancelSubscription() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: cancelSubscription,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: paymentKeys.latestSubscription() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.isActive() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.canEnable() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.canDisable() });
        },
    });
}

export function useUpgradeSubscription() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: upgradeSubscription,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: paymentKeys.latestSubscription() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.isActive() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.canEnable() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.canDisable() });
        },
    });
}
