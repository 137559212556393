import { Alert, Snackbar } from "@mui/material";

/**
 * The Snackbar component appears temporarily and floats above the UI to provide users with (non-critical) updates on an app's processes.
 */
export default function SnackBar({ open, handleClose, severity, message, autoHideDuration = 5000 }) {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
