import { Button, Typography } from "@mui/material";
import { useDeleteConfiguredSenderWorkflows, useUpdateUserTrainingStatus } from "hooks/api/mailbot/mutations";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";

export default function UndoButton({ profile, trainingLabel = "", undoTraining = false, undoCustomRule = false }) {
    const deleteConfiguredSenderWorkflowsMutation = useDeleteConfiguredSenderWorkflows();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const updateUserTrainingStatusMutation = useUpdateUserTrainingStatus();
    if (mailBotEnabledPending) {
        return null;
    }

    return (
        <Button
            disabled={!mailBotEnabled}
            disableElevation
            onClick={() => {
                if (undoTraining) {
                    updateUserTrainingStatusMutation.mutate({ profiles: [profile], trainingLabel: trainingLabel });
                } else if (undoCustomRule) {
                    deleteConfiguredSenderWorkflowsMutation.mutate({ senderProfileIds: [profile.id] });
                }
            }}
            sx={{
                background: "#EFBA421A",
                borderRadius: "4px",
                width: "100px",
                height: "40px",
                "&:hover": {
                    background: "#FFAE02",
                },
            }}
            variant="contained"
        >
            <Typography fontWeight={500} color="#1B1301" fontSize={"14px"}>
                Undo
            </Typography>
        </Button>
    );
}
