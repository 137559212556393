import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Paper, Typography } from "@mui/material";
import UndoButton from "components/Common/UndoButton";
import { getDisplayNumber } from "utils/formatter";
import { CustomRule } from "./CustomRule";
import ZapMailButton from "./ZapMailButton";
import ZappedText from "./Zapped";

function ActionsCell({ profile }) {
    const workflowConfigured = !!profile.workflow_tag;
    const senderBlockedByEmailZap = !!profile.blocked_by_emailzap;
    const senderBlockedByUser = profile.user_training === "black_list";
    if (!workflowConfigured && !senderBlockedByEmailZap && !senderBlockedByUser) {
        return (
            <Box display={"flex"} gap={"8px"} alignItems={"center"} height={"100%"}>
                <ZapMailButton profile={profile} />
                <CustomRule profile={profile} ruleEnabled={false} />
            </Box>
        );
    } else if (senderBlockedByEmailZap) {
        return (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                <ZappedText title={"Zapped by EmailZap"} />
                <UndoButton undoTraining={true} trainingLabel="white_list" profile={profile} />
            </Box>
        );
    } else if (senderBlockedByUser) {
        return (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                <ZappedText title={"Zapped by you"} />
                <UndoButton undoTraining={true} trainingLabel="" profile={profile} />
            </Box>
        );
    } else {
        return <CustomRule profile={profile} ruleEnabled={true} />;
    }
}

export default function AutoCleanerCard({ profile }) {
    return (
        <Paper
            variant="outlined"
            sx={{
                width: "100%",
                boxShadow: "0px 1px 1px 0px #0000001A",
                border: 0,
                borderRadius: 0,
                background: "white",
            }}
        >
            <Box p={2}>
                <Box display={"flex"} alignItems={"center"} gap={"8px"} marginBottom={"10px"}>
                    <MailOutlineIcon
                        sx={{
                            color: "#111827",
                        }}
                    />
                    <Typography color="##111827" variant="subtitle2" fontWeight={500} lineHeight={"16.94px"} noWrap>
                        {profile.sender_email}
                    </Typography>
                </Box>
                <Box display={"flex"} gap={"8px"} marginBottom={"20px"}>
                    <Typography color="#616265" variant="body2">
                        Mails: <strong>{getDisplayNumber(profile.total_count)}</strong>
                    </Typography>
                    <Typography color="#616265" variant="body2">
                        Read: <strong>{(profile.read_fraction * 100).toFixed(0)}%</strong>
                    </Typography>
                </Box>
                <Box>
                    <ActionsCell profile={profile} />
                </Box>
            </Box>
        </Paper>
    );
}
